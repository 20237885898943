import React from 'react';

const ButtonLoader = () => {
  return (
    <div class='button-loader '>
      <div class='button-spin'></div>
    </div>
  );
};

export default ButtonLoader;
